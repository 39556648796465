import { ChangeDetectionStrategy, Component, effect, HostListener, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BottomSheetConfig, BottomSheetService } from '../../Services/bottom-sheet.service';

@Component({
   selector: 'app-bottom-sheet',
   standalone: true,
   imports: [CommonModule],
   templateUrl: './bottom-sheet.component.html',
   styleUrl: './bottom-sheet.component.scss',
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class BottomSheetComponent {
   isVisibleContent = signal(false);
   isVisibleContainers = signal(false);
   component = signal<any>(null);
   template = signal<any>(null);
   config = signal<BottomSheetConfig | null>(null);
   bottomSheetService = inject(BottomSheetService);

   @HostListener('click', ['$event'])
   click(event: MouseEvent): void {
      if (this.config()?.disableClose) {
         return;
      }
      if ((event.target as HTMLElement).classList.contains('bottom-sheet-container')) {
         if (!this.bottomSheetService.isOpening()) {
            this.bottomSheetService.closeBottomSheet();
         }
      }
   }

   constructor() {
      effect(
         () => {
            this.isVisibleContent.set(this.bottomSheetService.isVisibleContent());
            this.isVisibleContainers.set(this.bottomSheetService.isVisibleContainers());
            if (this.bottomSheetService.component()) {
               this.component.set(this.bottomSheetService.component());
               this.template.set(null);
            } else {
               this.component.set(null);
               this.template.set(this.bottomSheetService.template());
            }
            if (this.bottomSheetService.config()) {
               this.config.set(this.bottomSheetService.config() as BottomSheetConfig);
            } else {
               this.config.set(null);
            }
         },
         { allowSignalWrites: true }
      );
   }

   closeBottomSheet() {
      this.bottomSheetService.closeBottomSheet();
   }
}
