import { Routes } from '@angular/router';
import { AuthGurad } from './utils/guard/auth.guard';

export const routes: Routes = [
   {
      path: '',
      loadChildren: () => import('./main/main.module').then(m => m.MainModule),
      canActivate: [AuthGurad]
   },
   { path: 'login', loadChildren: () => import('./login-signup/login-signup.module').then(m => m.LoginSignupModule) },
   {
      path: 'privacy-policy',
      loadComponent: () =>
         import('./general-components/privacy-policy/privacy-policy.component').then(m => m.PrivacyPolicyComponent)
   },
   {
      path: 'page-not-found',
      loadComponent: () =>
         import('./general-components/page-not-found/page-not-found.component').then(c => c.PageNotFoundComponent)
   },
   { path: '**', pathMatch: 'full', redirectTo: 'page-not-found' }
];
