import { Component, OnInit, OnDestroy, signal, input } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingService } from './loading.service';

@Component({
   selector: 'app-loading',
   templateUrl: './loading.component.html',
   styleUrls: ['./loading.component.scss'],
   standalone: true
})
export class LoadingComponent implements OnInit, OnDestroy {
   loadingSubscription!: Subscription;

   loading = signal<boolean>(false);

   position = input<string>();
   type = input<string>();
   defaultColor = input<string>();

   constructor(private loadingScreenService: LoadingService) {}

   ngOnInit() {
      this.loadingSubscription = this.loadingScreenService.loadingStatus.subscribe(value => {
         this.loading.set(value);
      });
   }

   ngOnDestroy() {
      this.loadingSubscription.unsubscribe();
   }
}
