import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { TempService } from './temp.service';

@Component({
    selector: 'app-temp-page-for-api-checking',
    templateUrl: './temp-page-for-api-checking.component.html',
    styleUrls: ['./temp-page-for-api-checking.component.scss'],
    standalone: true,
    imports: [ReactiveFormsModule]
})
export class TempPageForApiCheckingComponent {
  url: FormControl = new FormControl(null, [Validators.required]);
  method: FormControl = new FormControl('GET', [Validators.required]);
  token: FormControl = new FormControl(null, [Validators.required]);
  body: FormControl = new FormControl(null);
  constructor(private apiService: TempService) { }

  onChangeMethod(event: any) {
    if (['POST', 'PUT'].includes(event.target.value))
      this.body.validator = Validators.required;
    else
      this.body.validator = null;

  }
  sendRequest() {
    this.apiService.callAPI(this.method.value, this.url.value, this.token.value, this.body.value);
  }
}
