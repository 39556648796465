<div class="d-flex flex-column w-75 mx-auto py-4 justify-content-start align-items-baseline">
  <div class="form-group mb-3">
    <label for="method">Method</label>
    <select name="method" id="method" class="form-select" (change)="onChangeMethod($event)" [formControl]="method">
      <option value="GET">GET</option>
      <option value="POST">POST</option>
      <option value="PUT">PUT</option>
      <option value="DELETE">DELETE</option>
    </select>
  </div>
  <div class="form-floating w-100 mb-3">
    <input type="text" placeholder="URL" id="url" class="form-control " [formControl]="url">
    <label for="url">URL</label>
  </div>
  @if (method.value === 'POST' || method.value === 'PUT') {
    <div class="form-floating w-100 mb-3">
      <textarea class="form-control  lh-16" id="body" placeholder="body" style="height: 120px;"  [formControl]="body"></textarea>
      <label for="body">Body</label>
    </div>
  }
  <div class="form-floating w-100 mb-1">
    <textarea class="form-control  lh-16" id="token" placeholder="token"  style="height: 120px;" [formControl]="token"></textarea>
    <label for="token">Token</label>
  </div>
  <button type="button" class="btn btn-primary text-white my-3"
    [disabled]="body.invalid || token.invalid || url.invalid"
  (click)="sendRequest()">Send</button>
</div>
