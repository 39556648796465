import { Route, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { TempPageForApiCheckingComponent } from "./temp-page-for-api-checking.component";

const routes: Route[] = [
  {
    path: '', pathMatch: 'full', component: TempPageForApiCheckingComponent,
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class TempRoutingModule{}
