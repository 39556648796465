import { Component, OnInit, OnDestroy,Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingService } from './loading.service';
import { NgStyle, NgClass } from '@angular/common';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
    standalone: true,
    imports: [NgStyle, NgClass]
})
export class LoadingComponent implements OnInit,OnDestroy {
  loading!: boolean;
  loadingSubscription!: Subscription;
  @Input() position!: string;
  @Input() type!: string;
  @Input() defaultColor!:string;

  constructor(private loadingScreenService:LoadingService
    ) { }

  ngOnInit() {
    this.loadingSubscription = this.loadingScreenService.loadingStatus.subscribe((value) => {
      this.loading = value;
    });
  }

  ngOnDestroy() {
    this.loadingSubscription.unsubscribe();
  }

}
