import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class TempService{
  constructor(private httpClient: HttpClient) { }

  callAPI(method: string, url: string, token: string, body: string ='') {
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`)
    switch (method) {
      case 'GET':
        this.httpClient.get(url, { headers: header, params: new HttpParams().set('skip-interceptor',true)}).subscribe(() => { })
        break;
      case 'PUT':
        this.httpClient.put(url, JSON.parse(body), { headers: header, params: new HttpParams().set('skip-interceptor',true) }).subscribe(() => { })
        break;
      case 'POST':
        this.httpClient.post(url, JSON.parse(body), { headers: header, params: new HttpParams().set('skip-interceptor',true) }).subscribe(() => { })
        break;
      case 'DELETE':
        this.httpClient.delete(url, { headers: header, params: new HttpParams().set('skip-interceptor',true) }).subscribe(() => { })
        break;
      default:
        break;
    }
  }
}
