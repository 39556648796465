import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { TempPageForApiCheckingComponent } from './temp-page-for-api-checking.component';
import { TempRoutingModule } from './temp.routing.module';

@NgModule({
  imports: [
    CommonModule,
    TempRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgbNavModule,
    TempPageForApiCheckingComponent
  ]
})
export class TempModule {}
