import { Component, DestroyRef, inject, signal } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { LoadingComponent } from './shared/components/loading component/loading.component';
import { RouterOutlet } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
   selector: 'app-root',
   templateUrl: './app.component.html',
   styleUrls: ['./app.component.scss'],
   standalone: true,
   imports: [RouterOutlet, LoadingComponent]
})
export class AppComponent {
   title = 'Tiktera';

   animateTransition = signal<boolean>(true);

   private swUpdate = inject(SwUpdate);
   private destroyRef = inject(DestroyRef);

   constructor() {
      this.checkForUpdate();
   }

   checkForUpdate() {
      this.swUpdate.versionUpdates.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
         next: value => {
            if (value.type === 'VERSION_READY') {
               window.location.reload();
            }
         }
      });
   }
}
