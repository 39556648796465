import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { LoadingService } from "./loading.service";
import { finalize } from "rxjs/operators";


@Injectable()
export class LoadingScreenInterceptor implements HttpInterceptor {
  activeRequests: number = 0;

  constructor(private loadingScreenService: LoadingService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let loading = request.headers.get('default-loading');
    if (this.activeRequests === 0 && loading !== "false" &&
      !request.url.includes('social') &&
      !request.url.includes('usersearch') &&
      !request.url.includes('noti')) {
      this.loadingScreenService.startLoading();
  }

    this.activeRequests++;
    return next.handle(request).pipe(
      finalize(() => {
        this.activeRequests--;
        if (this.activeRequests === 0) {
          setTimeout(()=>{
            this.loadingScreenService.stopLoading();
          },500)
        }
      })
    )

  };

}
