import { Component, TemplateRef } from '@angular/core';
import { ToastService } from '../../Services/toasy.service';
import { NgStyle, NgTemplateOutlet } from '@angular/common';
import { NgbToast } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-toast-container',
    templateUrl: './toast-container.component.html',
    styleUrls: ['./toast-container.component.scss'],
    standalone: true,
    imports: [NgbToast, NgStyle, NgTemplateOutlet]
})
export class ToastContainerComponent{
	constructor(public toastService: ToastService) {}

	isTemplate(toast: any) {
		return toast.textOrTpl instanceof TemplateRef;
  }

  removeToast(toast: any) {
    this.toastService.remove(toast)
  }
}
