<div  class="d-none d-md-block">
  @switch (type=='blurry') {
    @case (true) {
      <div >
        @if (loading) {
          <div [ngStyle]="{'position': position}" class="loading">
            <svg class="spinner" viewBox="0 0 50 50">
              <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
            </svg>
          </div>
        }
      </div>
    }
    @case (false) {
      <div>
        <div  class="loading2">
          <div [ngClass]="{'complete': !loading}"
            [ngStyle]="{'background-color':defaultColor ? defaultColor : 'var(--theme-color)'}">
            <div class="shadow"></div>
            <span
              [ngStyle]="{'border-top-color':defaultColor ? defaultColor : 'var(--theme-color)'}"
              [ngStyle]="{'border-left-color':defaultColor ? defaultColor : 'var(--theme-color)'}"
            ></span>
          </div>
        </div>
      </div>
    }
  }
</div>
