@for (toast of toastService.toasts; track toast; let i = $index) {
  <ngb-toast [class]="toast.type"
    class="toast-container position-fixed p-0 shadow-md bg-light d-flex justify-content-between align-content-center"
    [ngStyle]="{bottom: (((i)*65) + 15)+'px'}"
    [autohide]="true"
    [delay]="toast.delay || 5000" (hidden)="toastService.remove(toast)">
    <div>
      @if (toast.type === 'success') {
        <i class="feather-check-circle rounded-circle btn-sm btn bg-success me-3 text-white"></i>
      }
      @if (toast.type === 'fail') {
        <i class="feather-x rounded-circle btn-sm btn bg-danger me-3 text-white"></i>
      }
      @if (toast.type === 'warning') {
        <i class="feather-alert-triangle rounded-circle btn-sm btn bg-warning me-3 text-white"></i>
      }
      @if (toast.type === 'info') {
        <i class="feather-info rounded-circle btn-sm btn bg-primary me-3 text-white"></i>
      }
      @if (isTemplate(toast)) {
        <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
      } @else {
        {{ toast.textOrTpl }}
      }
      <ng-template class="d-block" #text>{{ toast.textOrTpl }}</ng-template>
    </div>
    <i class="feather-x btn btn-sm rounded-circle ms-3 cursor-pointer " (click)="removeToast(toast)"></i>
  </ngb-toast>
}
