<div class="d-block">
   @switch (type() == 'blurry') {
      @case (true) {
         <div>
            @if (loading()) {
               <div [style]="{ position: position() }" class="loading">
                  <svg class="spinner" viewBox="0 0 50 50">
                     <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
                  </svg>
               </div>
            }
         </div>
      }
      @case (false) {
         <div>
            <div class="loading2">
               <div
                  [class.complete]="!loading()"
                  [style]="{ 'background-color': defaultColor() ? defaultColor() : 'var(--theme-color)' }"
               >
                  <div class="shadow"></div>
               </div>
            </div>
         </div>
      }
   }
</div>
