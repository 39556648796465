import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from 'src/app/login-signup/services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  helper = new JwtHelperService();
  private isRefreshing: boolean = false;
  private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Check if the request is in the whitelist
    if (this.isInWhiteList(request.url)) {
      return next.handle(request).pipe(
        catchError(error => {
          return throwError(() => new Error(error));
        })
      );
    }

    // Skip interceptor if required
    if (request.params.get('skip-interceptor')) return next.handle(request);

    const storedToken = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken');

    if (storedToken && refreshToken) {
      const decodeToken = this.helper.decodeToken(storedToken);
      if (!decodeToken) this.navigateToLogin();
    } else this.navigateToLogin();

    // Add the token to the request headers
    request = this.addTokenHeader(request, storedToken as string);

    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next, refreshToken as string);
        } else {
          return throwError(error);
        }
      })
    );
  }

  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler,
    refreshToken: string
  ): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken(refreshToken).pipe(
        switchMap((tokens: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(tokens.access);
          this.authService.setLoggedIn(tokens);
          return next.handle(this.addTokenHeader(request, tokens.access));
        }),
        catchError(error => {
          this.isRefreshing = false;
          this.navigateToLogin(); // Handle refresh token failure by logging out user
          return throwError(error);
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.addTokenHeader(request, token as string));
        })
      );
    }
  }

  addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`)
    });
  }

  navigateToLogin() {
    const returnURL = window.location.pathname;
    this.authService.logOut();
    this.router.navigate(['/login'], {
      queryParams: { returnURL: returnURL }
    });
  }

  private isInWhiteList(url: string): boolean {
    return environment.whiteListUrls.some(whiteListUrl => url.includes(whiteListUrl));
  }
}
